<template>
	<div v-if="loaded" class="radimix-pealeht" style="height: 100%;">
		<PealehtHeading/>
		<Info/>
	</div>
	<div v-else>Laadimine...</div>
</template>

<script>
	import PealehtHeading from '@/components/pealeht/radimix-heading.vue'
	import Info from '@/components/pealeht/info_kastid.vue'

	import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';

	export default {
		components: {
			PealehtHeading,
			Info
		},

		data() {
			return {
				loaded: false
			}
		},

		async mounted() {
			const sisu_moodul = getModule(SisuMoodul, this.$store)
			
			await sisu_moodul.get_pealdis()
			await sisu_moodul.get_info_kastid()
			await sisu_moodul.get_lingid()
			await sisu_moodul.get_teated()
			this.loaded = true
		}
	}

</script>