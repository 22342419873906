<template>
<div class="radimix-registeerumis-vorm">
	<div class="registeerimis_vorm">
		<vk-grid divided class="uk-child-width-expand@s">
			<div class="registeerumis-sisu" v-if="!taidetud">
				<p v-html="this.sisu"></p>
			</div>
			<div class="radimix-registeeru">
				<div v-if="validate_error" class="uk-alert-danger" uk-alert>
				    <a class="uk-alert-close" uk-close></a>
				    <p>Kõik vajalikud väljad pole täidetud, vaata registeerimisvorm üle ja proovi uuesti.</p>
				</div>
				<form class="uk-form-stacked" v-if="!taidetud">
					<div class="uk-margin">
						<label class="uk-form-label" for="form-stacked-text">Email</label>
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon" uk-icon="icon: mail"></span>
							<input class="uk-input" v-model.lazy="email" type="email">
						</div>
					</div>
					<vk-grid>
						<div class="uk-width-1-2">
							<label class="uk-form-label" for="form-stacked-text">Eesnimi</label>
							<div class="uk-inline uk-width-1-1">
								<span class="uk-form-icon" uk-icon="icon: user"></span>
								<input class="uk-input" v-model.lazy="eesnimi" type="text">
							</div>
						</div>

						<div class="uk-width-1-2">
							<label class="uk-form-label" for="form-stacked-text">Perekonnanimi</label>
							<div class="uk-inline uk-width-1-1">
								<span class="uk-form-icon" uk-icon="icon: user"></span>
								<input class="uk-input" v-model.lazy="perekonnanimi" type="text">
							</div>
						</div>
					</vk-grid>

					<div class="uk-margin">
						<label class="uk-form-label" for="form-stacked-text">Isikukood</label>
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon" uk-icon="icon: credit-card"></span>
							<input class="uk-input" v-model.lazy="isikukood" type="number">
						</div>
					</div>

					<div class="uk-margin">
						<label class="uk-form-label" for="form-stacked-text">Telefoninumber</label>
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon" uk-icon="icon: phone"></span>
							<input class="uk-input"  v-model.lazy="telefoninumber" type="number">
						</div>
					</div>

					<div class="uk-margin">
						<label class="uk-form-label" for="form-stacked-text">Address</label>
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon" uk-icon="icon: home"></span>
							<input class="uk-input" v-model.lazy="address" type="text">
						</div>
					</div>

					<div class="uk-margin">
						<label class="uk-form-label" for="form-stacked-text">Lisa informatsioon</label>
		            	<textarea class="uk-textarea" v-model.lazy="lisa" rows="5" placeholder="Kui sul on lisa küsimusi autokooli kohta siis saad seda siia kirjutada, sinuga võetakse ühendust emaili või telefoni teel."></textarea>
		        	</div>
				</form>
				<vk-button type="primary" v-if="!taidetud" class="uk-width-1-1 uk-margin-small-right" @click="registeeru();">Registeeru</vk-button>

			</div>
		</vk-grid>

	</div>
	
</div>
</template>
<script>
	export default {
		data() {
			return {
				email: null,
				eesnimi: null,
				perekonnanimi: null,
				isikukood: null,
				telefoninumber: null,
				address: null,
				lisa: '',

				validate_error: false,
				taidetud: false,
			}
		},
		methods: {
			registeeru() {
				const regExp = /[a-zA-Z]/g;

				if ( this.email == null || this.eesnimi == null || this.perekonnanimi == null || this.isikukood == null || regExp.test(this.isikukood) || this.telefoninumber == null || regExp.test(this.telefoninumber) || this.address == null ) {
					this.validate_error = true
				} else {
					this.taidetud = true;
					this.$emit("submit", {
						email: this.email,
						eesnimi:this.eesnimi,
						perekonnanimi:this.perekonnanimi,
						isikukood:this.isikukood,
						telefoninumber:this.telefoninumber,
						address:this.address,
						lisa:this.lisa
					})
				}
			},
			sulge() {
				this.$emit("cancel")
			}
		},

		props: [
			'sisu'
		]
	}
</script>

<style>

.uk-green:hover {
	background-color: green;
	color: white;
}

.uk-green {
	background-color: darkgreen;
	color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>