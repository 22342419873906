<template>
<div class="radimix-kontakteerumine">
	<div class="registeerimis_vorm">
		<vk-grid divided class="uk-child-width-expand@s">
			<div class="kontakt-sisu">
				<p v-html="sisu"></p>
				<div class="radimix-küsimused">
					<div v-if="validate_error" class="uk-alert-danger" uk-alert>
					    <a class="uk-alert-close" uk-close></a>
					    <p>Kõik vajalikud väljad pole täidetud, vaata registeerimisvorm üle ja proovi uuesti.</p>
					</div>

					<div v-if="this.kysimus_staatus == 3" class="uk-alert-success uk-margin" uk-alert>
				    	<p>{{ this.kysimus_feedback }}</p>
					</div>

					<div v-if="this.kysimus_staatus == 2" class="uk-alert-danger uk-margin" uk-alert>
					    <p>{{ this.kysimus_feedback }}</p>
					</div>
					<form class="uk-form-stacked">
						<div class="uk-margin">
							<label class="uk-form-label" for="form-stacked-text">Email</label>
							<div class="uk-inline uk-width-1-1">
								<span class="uk-form-icon" uk-icon="icon: mail"></span>
								<input class="uk-input" v-model.lazy="email" type="email">
							</div>
						</div>

						<div class="uk-margin">
							<label class="uk-form-label" for="form-stacked-text">Telefoninumber</label>
							<div class="uk-inline uk-width-1-1">
								<span class="uk-form-icon" uk-icon="icon: phone"></span>
								<input class="uk-input"  v-model.lazy="telefoninumber" type="number">
							</div>
						</div>

						<div class="uk-margin">
							<label class="uk-form-label" for="form-stacked-text">Küsimus</label>
			            	<textarea class="uk-textarea" v-model.lazy="kysimus" rows="5" placeholder="Kui sul on lisa küsimusi autokooli kohta siis saad seda siia kirjutada, sinuga võetakse ühendust emaili või telefoni teel."></textarea>
			        	</div>

			        	<vk-button type="primary" class="uk-width-1-1 uk-margin-small-right" @click="saada()">Saada</vk-button>
					</form>
				</div>
			</div>
			<div class="radimix-asukoht">
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d523.0174129513575!2d26.722466129285806!3d58.375362198833436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eb36dfe8939e13%3A0xac23b4615c58fb6d!2sWilhelm%20Struve%205%2C%2051003%20Tartu!5e0!3m2!1set!2see!4v1618577525830!5m2!1set!2see" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
			</div>
		</vk-grid>
	</div>
</div>
</template>
<script>
	import { getModule } from 'vuex-module-decorators';
  	import RegModul from '@/store/modules/radimix_login';


	export default {
		data() {
			return {
				email: null,
				telefoninumber: null,
				kysimus: null,

				kysimus_feedback: null,
				kysimus_staatus: null,

				validate_error: false
			}
		},

		methods: {
			sulge() {
				this.$emit("cancel")
			},

			async saada() {
				const regExp = /[a-zA-Z]/g;

				if ( this.email == null || this.telefoninumber == null || regExp.test(this.telefoninumber) || this.kysimus == null ) {
					this.validate_error = true
				} else {
					const register_moodul = getModule(RegModul, this.$store)

					await register_moodul.saada_kysimus({
						email: this.email,
						telefon: this.telefoninumber,
						kysimus: this.kysimus
					})

					this.kysimus_staatus = register_moodul.kysimus_staatus
					this.kysimus_feedback = register_moodul.kysimus_message
				}
			}
		},

		props: [
			'sisu'
		]
	}
</script>

<style>

.uk-green:hover {
	background-color: green;
	color: white;
}

.uk-green {
	background-color: darkgreen;
	color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>