<template>
	<div class="radimix-lingid">
		<vk-button class="uk-width-1-1" @click="show_modal = true" type="primary" :style="{backgroundColor: m_cta_color}">{{ m_cta }}</vk-button>

		<vk-modal :show.sync="show_modal" size="container" overflow-auto :stuck="true">
			<vk-modal-close @click="show_modal = false"></vk-modal-close>
	  		<vk-modal-title slot="header">{{ m_pealkiri }}</vk-modal-title>

	  		<div v-if="m_content_id == 1">
	  			<div v-if="this.opilane_reg_state == 3" class="uk-alert-success uk-margin" uk-alert>
				    <p>{{ this.opilane_reg_message }}</p>
				</div>

				<div v-if="this.opilane_reg_state == 2" class="uk-alert-danger uk-margin" uk-alert>
				    <p>{{ this.opilane_reg_message }}</p>
				</div>
	  		</div>

	  		<div v-if="m_content_id == 0 || m_content_id == 3" class="radimix-lingid-sisu" v-html="m_sisu"></div>
	  		<Kontakteerumine v-else-if="m_content_id == 2" :sisu="m_sisu" @cancel="sulge()"/>
	  		<Registeerumine v-else-if="m_content_id == 1" :sisu="m_sisu" @submit="registeerumine($event)" @cancel="sulge()"/>
	  		<hr>
	    	<vk-button-link
	    		v-if="m_content_id == 3"
	    		class="uk-margin-bottom uk-width-1-1" 
	    		:style="{ backgroundColor: m_cta_color }"
	    		:href="m_cta_href" 
	    		target="_blank" 
	    		type="primary">
	    		{{ m_cta }}
	    	</vk-button-link>
	    	<vk-button class="uk-margin-small-right uk-width-1-1" @click="show_modal = false">Sulge</vk-button>
		</vk-modal>
	</div>
</template>

<script>
	import Registeerumine from '@/components/pealeht/moodulid/registeerumine.vue'
	import Kontakteerumine from '@/components/pealeht/moodulid/kontakteeru.vue'

	import { getModule } from 'vuex-module-decorators';
  	import RegModul from '@/store/modules/radimix_login';

	export default {

		data() {
			return {
				show_modal: false,

				opilane_reg_state: null,
				opilane_reg_message: null,

				m_content_id: this.content_id,
				m_pealkiri: this.pealkiri,
				m_sisu: this.sisu,
				m_cta: this.cta,
				m_cta_color: this.cta_color,
				m_cta_href: this.cta_href
			}
		},

		props: [
			'content_id',
			'pealkiri',
			'sisu',
			'cta',
			'cta_color',
			'cta_href'
		],

		methods: {
			async registeerumine(data) {
				const register_moodul = getModule(RegModul, this.$store)

				await register_moodul.reg_opilane(data)

				this.opilane_reg_state = getModule(RegModul, this.$store).opilane_reg_state
				this.opilane_reg_message = getModule(RegModul, this.$store).opilane_reg_message
			},

			sulge() {
				this.show_modal = false;
			}
		},

		components: {
			Registeerumine,
			Kontakteerumine
		}
	}
</script>