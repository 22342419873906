<template>
	<div class="radimix-informatsioon uk-padding">
		<vk-grid class="uk-child-width-1-3@m" :matched="true">
			<div class="radimix-informatsiooni-kastid" v-for="item in this.info" :key="item['info_id']">
			    <vk-card hover>
			    	<vk-card-title class="uk-heading-bullet">{{ item['pealkiri']}}</vk-card-title>
			    	<p v-html="item['sisu']"></p>
			    </vk-card>
			</div>
		</vk-grid>
	</div>
</template>

<script>
	import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';

	export default {
		data() {
			return {
				info: null
			}
		},

		created() {
			this.info = getModule(SisuMoodul, this.$store).info_kastid
		}
	}
</script>