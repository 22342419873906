<template>
	<div class="radimix-heading uk-background-cover uk-padding-large"
		:style="{ backgroundImage: 'url(' + require('@/assets/taust.jpg') + ')' }">

		<vk-grid>
			<div class="uk-width-1-1@m">
				<div class="radimix-kiirlingid">
					<div class="">
						<vk-grid gutter="small" style=" margin-left: 10px; margin-right: 10px !important; margin-bottom: 3px;" class="uk-child-width-expand@s uk-text-center">
							<a href="https://www.mnt.ee/et" target="_blank" class="uk-label" style="margin-right: 3px; background-color: #f8f7f3;"><img width='100px' height="40px" src="https://radimix.ee/pildid_2022/transpordiamet.webp"></a>
							<a href="https://www.teooria.ee/" target="_blank" class="uk-label" style="padding: 1em; background-color: #4d4d4d;"><img width='150px' src="https://www.teooria.ee/upload/structureimages/Logo.svg"></a>
						</vk-grid>
					</div>
				</div>
				<vk-card>
					<vk-card-title>
					      {{ pealdis[0]['pealkiri'] }}
				
  					</vk-card-title>
  					<p v-html="pealdis[0]['sisu']"></p>
  					<hr class="uk-divider-icon">
  					<vk-grid gutter="small" class="uk-child-width-expand@s uk-text-center">
					  <div v-for="link in this.lingid" :key="link['lingi_id']">
					  	<Lingid
					  		:content_id="link['lingi_content_id']"
					  		:pealkiri="link['lingi_pealkiri']"
					  		:sisu="link['lingi_sisu']"
					  		:cta="link['lingi_cta']"
					  		:cta_color="link['lingid_cta_color']"
					  		:cta_href="link['lingid_cta_href']"/>
					  </div>

					</vk-grid>
				</vk-card>

				<div v-for="teade in this.teated" :key="teade['teate_id']" class="radimix-teade uk-margin-top">
					<div class="uk-alert-danger" uk-alert v-html="teade['teade']"></div>
				</div>
			</div>

		</vk-grid>

	</div>
</template>

<script>
	import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';

  	import Lingid from '@/components/pealeht/lingi_moodulid'
	
	export default {
		data() {
			return {
				pealdis: null,
				lingid: null,
				teated: null
			}
		},

		components: {
			Lingid
		},

		created() {
			this.pealdis = getModule(SisuMoodul, this.$store).pealdis
			this.lingid = getModule(SisuMoodul, this.$store).lingid
			this.teated = getModule(SisuMoodul, this.$store).teated
		}
	}
</script>

<style scoped>
.uk-button-eope {
	background-color: #f08e1e;
}

</style>